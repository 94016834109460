import React from 'react';
import SmallButton from './SmallButton';

export default function BasicModal(props) {
  return (
    <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-opacity-50 bg-gray-500">
      <div className="relative flex flex-col bg-white rounded-3xl overflow-hidden justify-between items-center shadow-md w-full max-w-2xl">
        <div className='w-full'>
          <div className="text-3xl font-bold pt-8 pl-8 pb-4 text-[#238D95]">{props.title}</div>
        </div>
          <div className="sm:px-10 px-5 pb-5 w-full">
            <div className="p-4">
              {props.children}
            </div>
            <div className="text-center">
              <SmallButton name="Close" type="solid" click={props.close} />
          </div>
        </div>
      </div>
    </div>
  )
}