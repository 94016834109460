import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { useContext, useState } from "react";
import DocumentCheckIcon from "./DocumentCheckIcon";
import { FirebaseContext } from "../../../contexts";

export default function TaskItem(props) {
  const { services } = useContext(FirebaseContext);
  const [isOpen, setIsOpen] = useState(false);
  const handleMakeComplete = (actionItemId) => {
    services.completeActionItem({ itemId: actionItemId }).then((res) => {
      console.log(res.data);
      alert('Action item marked as complete');
    })
  }
  
  return (
    <div className="border border-[#E5E7EB] rounded-[12px] p-3 mb-2">
      <div className="flex flex-row items-center pb-2">
        <div className="w-7 h-7 p-[7px] mr-3 rounded-lg border border-[#E5E7EB]">
          {props.icon ? props.icon : <DocumentCheckIcon className="w-3 h-3 text-[#854D0E]" />}
        </div>
        <div className="flex-1">
          <div className="text-sm font-medium text-[#1F2937]">{props['Title']}</div>
          <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">{props['Description']}</div>
          <div className="text-xs text-[#6B7280]">Posted {new Date(props['CreatedAt']).toLocaleDateString()}</div>
          <div className="text-xs text-[#1F2937] truncate">
            <a href={props['URL']}>{props['URL'].substring(0, 50) + (props['URL'].length > 50 ? '...': '')}</a>
          </div>
        </div>
        {
          !props['Automatic Completion'] || props['Completed'] ? 
          <div>
            <button onClick={() => setIsOpen(!isOpen)}>
              {!isOpen ? <ChevronRightIcon className="h-6 w-6 text-[#1F2937]" /> : <ChevronDownIcon className="h-6 w-6 text-[#1F2937]" />}
            </button>
          </div> : null
        }
      </div>
      {
        !props['Automatic Completion'] || props['Completed'] ? 
          <div className={`pt-2 pl-[40px] border-t border-[#E5E7EB]${!isOpen ? ' hidden' : 'block'}`}>
            <button className="text-[#0D9488] text-sm border-0" onClick={() => handleMakeComplete(props['Action Item ID'])}>Make as complete</button>
          </div> : null
      }
    </div>
  )
}