import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, FirebaseContext } from "../contexts";

export const UserContext = createContext({});

// TODO: Refactor to simply; perhaps, create user classes?
export const UserProvider = ({ children }) => {
  const { services } = useContext(FirebaseContext);
  const { googleCredentials, magicCredentials } = useContext(AuthContext);
  const [onboardingUser, setOnboardingUser] = useState(null);
  const [tutoringUser, setTutoringUser] = useState(null);
  const [tutorError, setTutorError] = useState(null);
  const [announcements, setAnnouncements] = useState([]);
  const [actionItems, setActionItems] = useState([]);

  const getAnnouncements = useCallback(async () => {
    return await services
      .getPortalAnnouncements()
      .then((result) => {
        setAnnouncements(result.data);
        return { ok: true, data: result.data };
      })
      .catch((error) => {
        return { error };
      });
  }, [services]);

  const getActionItems = useCallback(async () => {
    return await services
      .getActionItems()
      .then((result) => {
        setActionItems(result.data);
        return { ok: true, data: result.data };
      })
      .catch((error) => {
        return { error };
      });
  }, [services]);

  const getOnboardingUserData = useCallback(async () => {
    return await services
      .getOnboardingTutor()
      .then((result) => {
        setOnboardingUser(result.data);
        return { ok: true, data: result.data };
      })
      .catch((error) => {
        return { error };
      });
  }, [services]);

  const getTutorUserData = useCallback(async () => {
    return await services
      .getTutor()
      .then((result) => {
        setTutoringUser(result.data);
        return { ok: true, data: result.data };
      })
      .catch((error) => {
        setTutorError(error);
        return { error };
      });
  }, [services]);

  useEffect(() => {
    if (!magicCredentials) return;
    (async () => {
      await getOnboardingUserData();
    })();
  }, [getOnboardingUserData, magicCredentials]);

  useEffect(() => {
    if (!googleCredentials) return;
    (async () => {
      await getTutorUserData();
    })();
  }, [getTutorUserData, googleCredentials]);

  useEffect(() => {
    (async () => {
      await getAnnouncements();
    })();
  }, [getAnnouncements]);

  useEffect(() => {
    (async () => {
      await getActionItems();
    })();
  }, [getActionItems]);

  return (
    <UserContext.Provider
      value={{
        onboardingUser,
        tutoringUser,
        announcements,
        actionItems,
        tutorError,
      }}>
      {children}
    </UserContext.Provider>
  );
};
