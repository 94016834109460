import React from "react";
import StudentRow from "./StudentRow";
import { useTutoringUser } from "../../hooks";

export default function StudentGrid(props) {
  // const { meetings = {}, students = [], firstname } = props;
  const { user } = useTutoringUser();

  return (
    <div style={{ maxWidth: "1050px" }} className="mx-auto py-1 shadow-sm bg-white border rounded-3xl">
      <div className="flex flex-col">
        <div className="mx-auto overflow-x-auto min-w-full">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden rounded-3xl max-w-full">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className="hidden lg:table-row">
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6">
                      Student
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500">
                      Learning Portal
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-center text-xs font-medium uppercase tracking-wide text-gray-500">
                      Messages
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {Array.isArray(user.correspondents) &&
                    user.correspondents.map((student) => {
                      return (
                        <StudentRow
                          key={student.id}
                          student={student}
                          firstname={user.firstname}
                          setStudentInfo={props.setStudentInfo}
                        />
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
