import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
// import { BellIcon } from "@heroicons/react/outline";
import { Card, ExpandIcon } from "../../../components";
import TaskItem from "./TaskItem";
// import DocumentCheckIcon from "./DocumentCheckIcon";
// import Square2StackIcon from "./Square2StackIcon";
import TaskModalItem from "./TaskModalItem";
import BasicModal from "../../../components/core/BasicModal";
import { useTutoringUser } from "../../../hooks";

export default function Tasks() {
  const {actionItems} = useTutoringUser();

  const [showModal, setShowModal] = useState(false);

  const Header = () => {
    const count = actionItems?.filter((task, index) => !task['Completed'])?.length || 0;
    return (
      <div className="relative">
        <div className="text-center">
          <span className="font-bold text-xl text-[#1F2937]">Tasks</span>
          {count > 0 && <span className="rounded-[50px] bg-[#EF4444] text-white ml-2 inline-block w-6 h-6">{count}</span>}
        </div>
        {count > 0 && <div className="absolute right-0 top-0 mt-2 mr-2">
          <button className="bg-inherit border-none border-0" onClick={() => setShowModal(true)}>
            <ExpandIcon />
          </button>
        </div>}
      </div>
      );
  };

  const Footer = () => {
    if(!actionItems?.length || actionItems?.length === 0) return null;
    else
    return (
      <button className="bg-inherit border-none border-0 text-sm text-[#0D9488]" onClick={()=>setShowModal(true)}>
        View Completed Tasks
        <ChevronRightIcon className="h-4 w-4 inline-block ml-1" />
      </button>
    );
  };

  return (
    <>
    <Card header={<Header />} footer={<Footer />}>
      {
        actionItems?.length > 0 
        ? actionItems?.filter((task, index) => !task['Completed'])?.map((task, index) => (<TaskItem key={index} {...task} />))
        : <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">No available task</div>
      }
    </Card>
    {showModal && 
        <BasicModal title="Tasks" close={()=>setShowModal(false)} >
          {
              actionItems?.length > 0 
              ? actionItems?.filter((task, index) => task['Completed'])?.map((task, index) => <TaskModalItem key={index} {...task} />)
              : <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">No available task</div>
          }
        </BasicModal>
        }
    </>
  );
}
