export default function AnnouncementItem({ subject, message, createdAt }) {
  return (
    <div className="border border-[#E5E7EB] bg-[#F8FAFC] rounded-[12px] p-3 mb-2">
      <div className="relative">
        <div className="text-sm font-medium text-[#1F2937] relative">{subject}</div>
        <div className="absolute right-0 top-0 mt-2 mr-2 bg-[#80C7CE] w-[8px] h-[8px] rounded"></div>
      </div>
      <div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">{message}</div>
      <div className="text-xs text-[#6B7280]">Posted {new Date(createdAt).toLocaleDateString()}</div>
    </div>
  )
}