import {HomeIcon,} from "@heroicons/react/outline";
import {EXTERNAL_LINKS} from "../../constants/constants";

const navigation = [
  {
    name: "Dashboard",
    icon: HomeIcon,
    current: false,
    href: "dashboard",
    open: true,
    chevron: false,
    children: [
      {
        name: "Weekly Form",
        href: EXTERNAL_LINKS.WEEKLY_FORM,
        external: true,
      },
      {
        name: "Sign up for Office Hours",
        href: EXTERNAL_LINKS.OFFICE_HOURS,
        external: true,
      },
      {
        name: "Support Docs",
        href: "https://help.stepuptutoring.org/hc/en-us/categories/25737139094036-Tutors",
        external: true,
      },
      {
        name: "Contact Support Team",
        href: EXTERNAL_LINKS.CONTACT_SUPPORT_TEAM,
        external: true,
      },
      { name: "Test Session room",
        href: "https://student-portal.stepuptutoring.org/student/8OQZX6",
        external: true,
      },
      {
        name: "Training Resources",
        href: EXTERNAL_LINKS.TRAINING_RESOURCES,
        external: true,
      },
      // {
      //   name: "Add or Drop a Student",
      //   href: "https://web.miniextensions.com/28W2UNtj1zrniNjabT4k?prefill_Tutors=2024-t23596",
      //   external: true,
      // },
      {
        name: "Request a Substitute",
        href: EXTERNAL_LINKS.REQUEST_SUBSTITUTE,
        external: true,
      },
      {
        name: "Fill in as a Substitute",
        href: EXTERNAL_LINKS.FILL_IN_AS_SUBSTITUTE,
        external: true,
      },
      {
        name: "WS/CC/AC timesheet",
        href: EXTERNAL_LINKS.DAILY_TIME_SHEET,
        external: true,
      },
      {
        name: "Report a Bug",
        href: EXTERNAL_LINKS.REPORT_A_BUG,
        external: true,
      },
    ],
  },
];

export default navigation;
