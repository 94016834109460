import { useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { Card, ExpandIcon } from "../../../components";
import AnnouncementItem from "./AnnouncementItem";
import { useTutoringUser } from "../../../hooks";
import BasicModal from "../../../components/core/BasicModal";
import AnnouncementModalItem from "./AnnouncementModalItem";

export default function Announcements() {
  const {announcements} = useTutoringUser();
  const [showModal, setShowModal] = useState(false);

  // const announcements = [{
  //   title: "New Curriculum rol out",
  //   body: "Exciting news! We are rolling out the new curriculum over the next couple weeks. The curriculum will be focused around a math app called Eedi",
  //   postedAt: "2021-10-01T00:00:00.000Z",
  // }, {
  //   title: "Work Study Summer Programming",
  //   body: "Exciting news! We are rolling out the new curriculum over the next couple weeks. The curriculum will be focused around a math app called Eedi",
  //   postedAt: "2021-10-01T00:00:00.000Z",
  // }, {
  //   title: "Work Study Summer Programming",
  //   body: "Exciting news! We are rolling out the new curriculum over the next couple weeks. The curriculum will be focused around a math app called Eedi",
  //   postedAt: "2021-10-01T00:00:00.000Z",
  // }];

  const Header = () => {
    return (
      <div className="relative">
        <div className="text-center relative">
          <span className="font-bold text-xl text-[#1F2937]">Announcement</span>
          {announcements?.length > 0 && <span className="rounded-[50px] bg-[#EF4444] text-white ml-2 inline-block w-6 h-6">{announcements?.length}</span>}
        </div>
        {announcements?.length > 0 && <div className="absolute right-0 top-0 mt-2 mr-2">
          <button className="bg-inherit border-none border-0" onClick={() => setShowModal(true)}>
            <ExpandIcon />
          </button>
        </div>}
      </div>
    );
  };

  const Footer = () => {
    if (!announcements?.length) return null;

    return (
      <>
        {announcements?.length > 0 && <button className="bg-inherit border-none border-0 text-sm text-[#0D9488]" onClick={() => setShowModal(true)}>
          View All Updates
          <ChevronRightIcon className="h-4 w-4 inline-block ml-1" />
        </button>}
        {showModal && 
        <BasicModal title="Announcements" close={()=>setShowModal(false)} >
          {
              announcements?.length> 0 ? announcements.map((announcement, index) => <AnnouncementModalItem key={index} {...announcement} />):<div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">No available announcement</div>
          }
        </BasicModal>
        }
      </>
    );
  };

  return (
    <Card header={<Header />} footer={<Footer />}>
      {
        announcements?.length> 0 ? announcements.map((announcement, index) => <AnnouncementItem key={index} {...announcement} />):<div className="text-xs text-[#1F2937] overflow-hidden text-ellipsis whitespace-pre-wrap line-clamp-2">No available announcement</div>
      }
    </Card>
  );
}
