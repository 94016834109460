import React, { Fragment, useContext, useEffect, useState } from "react";
import { useOutlet } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Sidebar } from "../../containers";
import { FacebookIcon, InstagramIcon, LinkedInIcon, Loader } from "../../components";
import classNames from "../../utils/classNames";
import "./index.css";
import { ChevronRightIcon } from "@heroicons/react/solid";
import { UserContext } from "../../contexts";

export default function TutorPortalSidebar(props) {
  const { navigation, onSignOut, theme, user } = props;
  const outlet = useOutlet();
  const {tutoringUser} = useContext(UserContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
   const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  const social = [
    {
      name: "Facebook",
      href: "https://www.facebook.com/stepuptutoringg",
      icon: (props) => <FacebookIcon {...props} />,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/stepuptutoringg",
      icon: (props) => <InstagramIcon {...props} />,
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/step-up-tutoring",
      icon: (props) => <LinkedInIcon {...props} />,
    }
  ];

  const shareData = {
    title: "Step Up Tutoring",
    text: "Sign up to tutor or get tutoring here!",
    url: "https://www.stepuptutoring.org/"
  }

  async function shareWebsite() {
    if (navigator.share) {
      navigator.share(shareData).then(() => {console.log("Share Successful")}).catch(console.error)
    }
    else if (navigator.clipboard) {
      navigator.clipboard.writeText(shareData.url).then(() => {
        setIsModalOpen(true);
      });
    }
  }
  
  return (
    <>
    <Transition.Root show={isModalOpen} as={Fragment}>
    <Dialog as="div" className="fixed inset-0 flex items-center justify-center z-50" onClose={() => setIsModalOpen(false)}>
    <Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-30" />
    <Transition.Child
      as={Fragment}
      enter="transition ease-out duration-300"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div className="bg-white rounded-lg p-4">
        <Dialog.Title className="text-lg font-medium">
          Copied!
        </Dialog.Title>
        <div className="mt-2">
          <p className="text-sm text-gray-500">
            Our website link, www.stepuptutoring.org, has been copied to your clipboard.
          </p>
        </div>
      </div>
    </Transition.Child>
  </Dialog>
</Transition.Root>
      <div id='tutorPortal' className={width >= 768 ? "gradient" : "bg-white"}>
        {/* Mobile Sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden h-screen " onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <>
                  <Sidebar navigation={navigation} onSignOut={onSignOut} theme={theme} user={user} />
                </>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>

        {/* Desktop Sidebar */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed h-screen">
          <Sidebar navigation={navigation} onSignOut={onSignOut} theme={theme} user={user} />
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div
            className={classNames(
              width >= 768 ? "noWave" : "wave",
              "flex justify-between top-0 z-20 pl-1 bg-no-repeat bg-[center_bottom_20%] bg-[length:1280px] sm:pl-3 sm:bg-cover sm:h-16 sm:w-full xl:bg-contain 2xl:bg-top"
            )}>
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 md:hidden hover:text-gray-900"
              onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
            {
              width >= 768 && (
                <div className="py-5">
                  <div className="flex flex-row items-center">
                    <span className="text-blueGreen font-semibold text-xl mr-5">Welcome, {user?.firstname}👋</span>
                    <a href={tutoringUser ? tutoringUser['Availability Form'] : "#"} className="text-blueGreen text-sm flex flex-row items-center">
                      Update Availability <ChevronRightIcon className="text-blueGreen w-5 h-5" />
                    </a>
                  </div>
                  {/* <div>
                    <span className="text-blueGreen text-xs">Current Availability: </span>
                    <span className="text-blueGreen text-xs font-semibold font-semibold">Tuesday 3-4, Wednesday 5-7</span>
                  </div> */}
                </div>
              )
            }
            {width >= 768 && (
              <div className="mr-14 mt-3 inline-flex float-right space-x-8 pt-3 md:mt-0">
                {social.map((item, itemIdx) => (
                  <a
                    key={itemIdx}
                    href={item.href}
                    target="_blank"
                    className="inline-flex text-blueGreen"
                    rel="noreferrer">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
                <button
                  onClick={() => shareWebsite()}
                  className="bg-blueGreen h-fit text-white hover:border-white hover:bg-white hover:text-turquoise w-40 text-sm font-semibold rounded-xl drop-shadow-md  ">
                  Share with a friend
                </button>
              </div>
            )}
          </div>
          <main className="">{outlet ? outlet : <Loader />}</main>
        </div>
      </div>
    </>
  );
}